.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}
html, body, #root {
  width: 100%;
  height: 100%;
}
.mr-2 {
  margin-right: 8px;
}
